import React from 'react';

import Layout from '../components/layout';
import Footer from '../components/Footer';

import HaPic from '../images/ha.jpg';
import JamesPic from '../images/james-park.jpg';
import VisoLabsPic from '../images/Visolabs.png';
import Sidebar from '../components/Sidebar';

import addToMailchimp from 'gatsby-plugin-mailchimp';

import IconStory from '../icons/icon_storyboard.svg';
import IconCollab from '../icons/icon_collaboration.svg';
import IconCloud from '../icons/icon_cloud.svg';
import IconAI from '../icons/icon_ai.svg';

export default class IndexPage extends React.Component {
  state = {
    name: null,
    email: null,
    jobTitle: null,
  };

  _handleFormChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    });
  };

  _handleFormSubmit = e => {
    e.preventDefault();

    addToMailchimp(this.state.email, {
      NAME: this.state.name,
      JOBTITLE: this.state.jobTitle,
    })
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw 'Oh no! Something went wrong. Please try again, or email hello@visolabs.com for assistance.';
        } else {
          alert(
            'Thanks for registering your early interest! Please check your email for confirmation.'
          );
        }
      })
      .catch(err => {
        alert(err);
      });
  };

  render() {
    return (
      <Layout>
        <Sidebar />

        <div id="wrapper">
          <section id="intro" className="wrapper style1 fullscreen">
            <div className="inner">
              <img src={VisoLabsPic} alt="" style={{ maxWidth: '100%' }} />
              <p>
                Making visual storytelling more accessible so that more stories
                can be told
              </p>
              <section id="form">
                <h2>Sign up for early access</h2>
                <form onSubmit={this._handleFormSubmit}>
                  <input
                    type="text"
                    onChange={this._handleFormChange}
                    placeholder="name"
                    name="name"
                  />
                  <input
                    type="email"
                    onChange={this._handleFormChange}
                    placeholder="email"
                    name="email"
                  />
                  <input
                    type="text"
                    onChange={this._handleFormChange}
                    placeholder="job title"
                    name="jobTitle"
                  />
                  <input type="submit" />
                </form>
              </section>
            </div>
          </section>

          <section id="one" className="wrapper style1 fullscreen">
            <div className="inner">
              <h2>What we do</h2>
              <p>
                We make tools to help filmmakers, artists and anyone else to
                create and tell visual stories.
              </p>
              <div className="features">
                <section>
                  <div className="featureHeader">
                    <img className="icon" src={IconStory} alt="" />
                    <h3>Storyboarding for everyone</h3>
                  </div>

                  <div className="featureText">
                    <p>
                      Create professional frame compositions with our easy to
                      use templates and asset libraries.
                    </p>
                  </div>
                </section>
                <section>
                  <div className="featureHeader">
                    <img className="icon" src={IconCollab} alt="" />
                    <h3>Collaboration</h3>
                  </div>
                  <div className="featureText">
                    <p>
                      Share your projects with your team to work together and
                      review and share notes.
                    </p>
                  </div>
                </section>
                <section>
                  <div className="featureHeader">
                    <img className="icon" src={IconCloud} alt="" />
                    <h3>Cloud based</h3>
                  </div>
                  <div className="featureText">
                    <p>
                      No installation. No setup. Work on any device. Share with
                      anyone.
                    </p>
                  </div>
                </section>
                <section>
                  <div className="featureHeader">
                    <img className="icon" src={IconAI} alt="" />
                    <h3>Cutting edge AI</h3>
                  </div>
                  <div className="featureText">
                    <p>
                      Leverage state of the art generative AI techniques to
                      speed up work and open new creative possibilities.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section id="two" className="wrapper style1">
            <div className="inner">
              <h2>Who we are</h2>
              <br />
              <div className="who">
                <section className="profile">
                  <div className="content">
                    <div className="inner">
                      <img src={HaPic} alt="" />

                      <h3>Ha Le, CEO</h3>
                      <p>
                        Ha loves film, animation and working with creatives. She
                        previously co-founded two other startups and was a
                        strategy consultant in a former life.
                      </p>
                      <ul className="icons">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/hangocle/"
                            className="fa-linkedin"
                          >
                            <span className="label">LinkedIn</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/hangocle"
                            className="fa-twitter"
                          >
                            <span className="label">Twitter</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section className="profile">
                  <div className="content">
                    <div className="inner">
                      <img src={JamesPic} alt="" />

                      <h3>James Liu, CTO</h3>
                      <p>
                        James has a background in visual effects, software
                        development and machine learning. He loves helping and
                        supporting others in their creative work.
                      </p>
                      <ul className="icons">
                        <li>
                          <a
                            href="https://www.linkedin.com/in/jamesstevenliu/"
                            className="fa-linkedin"
                          >
                            <span className="label">LinkedIn</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/jjjjjameses"
                            className="fa-twitter"
                          >
                            <span className="label">Twitter</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>

          <section id="three" className="wrapper style1">
            <div className="inner">
              <h2>Get in touch</h2>
              <p>
                We love to talk to storyboard artists, directors,
                cinematographers, and anyone interested in creating visual
                stories. Tell us your story and let us know how we can help you.
              </p>
              <p>
                <a href="mailto:hello@visolabs.com">hello@visolabs.com</a>
              </p>
            </div>
          </section>
        </div>
        <Footer />
      </Layout>
    );
  }
}
